.heartbeat {
  height: 100%;
  width: 100%;
}

.heartbeatPath {
  stroke: #fff;
  stroke-width: 1px;
  fill: none;
  stroke-dasharray: 150, 200;
  stroke-dashoffset: 0;
  animation: pulse 4s infinite linear;
}

@keyframes pulse {
  0% {
    stroke-dashoffset: 0;
  }

  100% {
    stroke-dashoffset: 1050;
  }
}